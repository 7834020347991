import { Box, Divider, Grid } from "@material-ui/core";
import { Skeleton } from "@mui/material";
import React from "react";

const ProductDetailsSkeleton = () => {
  return (
    <div className="container">
      <Grid container>
        <Grid item xs={12} md={4} style={{ marginTop: "1rem" }}>
          <Skeleton
            variant="rounded"
            animation="wave"
            width={"100%"}
            height="32rem"
            style={{ paddingX: "2rem" }}
          />
        </Grid>

        <Grid
          item
          sx={12}
          md={8}
          style={{
            paddingLeft: "6rem",
          }}
        >
          <Skeleton
            variant="text"
            width={"20rem"}
            height={"4rem"}
            animation="wave"
          />
          <Divider />
          <Skeleton
            variant="text"
            width={"8rem"}
            height={"3rem"}
            animation="wave"
          />

          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: ".6rem",
              marginBottom: "2rem",
            }}
          >
            <Skeleton
              variant="rectangular"
              width={"16rem"}
              height={"1.4rem"}
              animation="wave"
            />
            <Skeleton
              variant="rectangular"
              width={"16rem"}
              height={"1.4rem"}
              animation="wave"
            />
          </Box>

          <Divider />

          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              marginTop: "2rem",
            }}
          >
            <Skeleton variant="rounded" height={"6rem"} width={"6rem"} />
            <Skeleton variant="rounded" height={"6rem"} width={"6rem"} />
            <Skeleton variant="rounded" height={"6rem"} width={"6rem"} />
          </Box>

          <Skeleton
            variant="text"
            width={"12rem"}
            height={"3rem"}
            animation="wave"
            style={{
              marginTop: ".5rem",
            }}
          />
          <Skeleton
            variant="text"
            width={"10rem"}
            height={"3rem"}
            animation="wave"
            style={{
              marginTop: ".2rem",
            }}
          />
          <Skeleton
            variant="text"
            width={"12rem"}
            height={"3rem"}
            animation="wave"
            style={{
              marginTop: ".2rem",
            }}
          />
          <Skeleton
            variant="text"
            width={"8rem"}
            height={"3rem"}
            animation="wave"
            style={{
              marginTop: ".2rem",
            }}
          />

          <Skeleton
            variant="text"
            width={"8rem"}
            height={"4.8rem"}
            animation="wave"
            style={{
              marginTop: "1rem",
            }}
          />

          <Box style={{ display: "flex", gap: "1rem" }}>
            <Skeleton variant="rounded" width={"6rem"} height="3rem" />
            <Skeleton variant="rounded" width={"10rem"} height="3rem" />
            <Skeleton variant="rounded" width={"10rem"} height="3rem" />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProductDetailsSkeleton;
